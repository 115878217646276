/* 날짜 네비게이션 */
.react-calendar__navigation {
    display: flex;
    justify-content: center;
}

.react-calendar__navigation button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.5rem;
    cursor: pointer;
}

.react-calendar__navigation button:hover {
    background-color: #0056b3;
}

/* 요일 */
.react-calendar__month-view__weekdays {
    display: flex;
    justify-content: space-between;
}

.react-calendar__month-view__weekdays__weekday {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    height: 40px;
}

/* 달력 */
.react-calendar__tile {
    background-color: white;
    color: #333;
    padding: 0.75rem;
    width: 50px;
    height: 50px;
}

.react-calendar__tile--active {
    background-color: #007bff;
    color: white;
}

.react-calendar__tile--active:hover {
    background-color: #0056b3;
}

.react-calendar__month-view__days__day--weekend {
    color: #d9534f;
}
