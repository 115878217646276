body,
header,
footer,
button,
input,
span,
div {
    margin: 0;
    font-family: "NotoSansRegular", -apple-system, BlinkMacSystemFont,
        "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

@font-face {
    src: url("/public/font/notosans/NotoSansKR-Medium.ttf") format("truetype");
    font-family: "NotoSansMedium";
    font-style: normal;
}

@font-face {
    src: url("/public/font/notosans/NotoSansKR-Regular.ttf") format("truetype");
    font-family: "NotoSansRegular";
    font-style: normal;
}
