.pagination {
    display: flex;
    list-style-type: none;
    padding: 0;
}

.page-item {
    margin: 0 5px;
}

.page-link {
    padding: 8px 16px;
    text-decoration: none;
    color: #007bff;
    border: 1px solid #dee2e6;
    border-radius: 4px;
}

.page-link:hover {
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.active .page-link {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
}

.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff;
    border-color: #dee2e6;
}
